








import { Component, Vue } from "vue-property-decorator";

@Component
export default class TheHeader extends Vue {
  url = "/";
}
