import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { component } from 'vue/types/umd';
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  // {
  //   path: "/r3gkPsebXWFLQzFSx4ZgTT4f2shK_6",
  //   name: "Admin",
  //   component: () => import("@/views/Admin.vue")
  // },
  {
    path: "/model-info/:modelName",
    name: "ModelInfoView",
    component: () => import("@/views/ModelInfoView.vue")
  },
  {
    path: "/video-info/:productId",
    name: "VideoInfoView",
    component: () => import("@/views/VideoInfoView.vue")
  },
  {
    path: "/maker/:maker",
    name: "MakerVideoView",
    component: () => import("@/views/MakerVideoView.vue")
  },
  {
    path: "/series/:series",
    name: "SeriesVideoView",
    component: () => import("@/views/SeriesVideoView.vue")
  },
  {
    path: "*",
    name: "NotFoundView",
    component: () => import("@/views/NotFoundView.vue")
  },
  {
    path: "/coming-soon",
    name: "ComingSoonView",
    component: () => import("@/views/ComingSoonView.vue")
  }
  // {
  //   path: "/test",
  //   name: "Test",
  //   component: () => import("@/views/TestView.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
