

























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class VideoListItem extends Vue {
  @Prop() readonly video: any | undefined;

  getVideoInfoURL(id: string) {
    const url = `/video-info/${id}`;
    return url;
  }

  getModelInfoURL(modelName: string) {
    const url = `/model-info/${modelName}`;
    return url;
  }

  getMakerURL(maker: string) {
    const url = `/maker/${maker}`;
    return url;
  }

  getSeriesURL(series: string) {
    const url = `/series/${series}`;
    return url;
  }
}
