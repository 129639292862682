import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import * as firebaseui from "firebaseui";

import { firebaseConfig } from "@/config/av-info-dev";

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const authObject = firebase.auth;
export const firestore = firebase.firestore;
export const storageOjbect = firebase.storage;

export const ui = new firebaseui.auth.AuthUI(firebase.auth());