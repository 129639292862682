










import { Component, Vue, Prop } from "vue-property-decorator";
import VideoListItem from "@/components/VideoListItem.vue";

@Component({
  components: {
    VideoListItem
  }
})
export default class VideoList extends Vue {
  @Prop(undefined) readonly videos: Array<object> | undefined;
}
