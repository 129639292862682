










import { Component, Vue } from "vue-property-decorator";
import TheHeader from "@/components/TheHeader.vue";
import TheNavigationBar from "@/components/TheNavigationBar.vue";
import TheFooter from "@/components/TheFooter.vue";

@Component({
  components: {
    TheHeader,
    TheNavigationBar,
    TheFooter
  }
})
export default class App extends Vue {}
