






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class TheNavigationBar extends Vue {
  //url = "/";
  homeURL = "/";
  modelURL = "/coming-soon";
  seriesURL = "/coming-soon";
  makerURL = "/coming-soon";

  handleClickMenu() {
    const navbarLinks = document.getElementsByClassName("navbar-links")[0];
    navbarLinks.classList.toggle("active");
  }
}
