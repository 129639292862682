












import { Component, Vue } from "vue-property-decorator";
import { db } from "@/scripts/firebase";

import VideoList from "@/components/VideoList.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
  components: {
    VideoList,
    Pagination
  }
})
export default class Home extends Vue {
  videos: Array<object> = [];

  async created() {
    const collectionRef = db.collection("videos");
    const videoDocument = await collectionRef.get();
    const videoDocumentData = videoDocument.docs.map(doc => doc.data());
    this.videos = videoDocumentData;
    console.log(`video: ${this.videos}`);
  }
}
